<template>
  <div>
    <div class="blog-recent-posts mt-3">
      <h6 class="section-label mb-75">
        <!-- {{ $t('generic.recentPosts') }} -->
        {{ $t('post-recommended') }}
      </h6>
      <b-media>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-row>
              <b-col cols="12">
                <b-card
                  no-body
                  img-left
                >
                  <b-skeleton-img
                    card-img="left"
                    width="100px"
                  />
                  <b-card-body>
                    <b-skeleton width="85%" />
                    <b-skeleton width="55%" />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col cols="12">
                <b-card
                  no-body
                  img-left
                >
                  <b-skeleton-img
                    card-img="left"
                    width="100px"
                  />
                  <b-card-body>
                    <b-skeleton width="85%" />
                    <b-skeleton width="55%" />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </template>
          <!-- Data -->
        </b-skeleton-wrapper>
      </b-media>
      <div v-if="recommendedNotices.length > 0">
        <b-media
          v-for="(recentpost,index) in recommendedNotices"
          :key="recentpost.hash"
          no-body
          :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link :to="{ name: 'new-details', params:{ id :recentpost.slug } }">
              <b-img
                v-if="recentpost.image"
                :src="recentpost.image.url_absolute"
                alt="Image"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link
                :to="{ name: 'new-details', params:{ id :recentpost.slug } }"
                class="text-body-heading"
              >
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.init_publication_date | formatDate }}
            </span>
          </b-media-body>
        </b-media>

      </div>
    </div>
  </div>
  <!--/ Search -->
</template>

<script>
export default {
  name: 'PostsSidebarNews',
  props: {
    recommendedNotices: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    }
  },
  watch: {
    // 'query.search': function querySearch() {
    //   this.$emit('filter-query', this.query)
    // },
  },
}

</script>

<style scoped>

</style>
