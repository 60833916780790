<template>
  <content-with-sidebar
    v-if="Object.keys(notice).length"
    class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <!-- img-height="600" -->
          <b-card>
            <b-card-title class="mt-1">
              <h1>{{ notice.title }}</h1>
            </b-card-title>
            <b-card-sub-title v-if="notice.club">
              <p>{{ notice.club.club_name }}</p>
            </b-card-sub-title>
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  v-if="notice.user.avatar_file_hash != 0 && notice.user.avatar_file_hash != null"
                  href="javascript:void(0)"
                  size="24"
                  :src="`${path}/${notice.user.avatar_media_file.url_relative}`"
                />
                <b-avatar
                  v-else
                  variant="secondary"
                  :text="notice.user.initials"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">{{ $t('generic.by') }}</small>
                <small>
                  <b-link class="text-body">{{ notice.user ? notice.user.full_name : '' }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ notice.init_publication_date | formatDate }}</small>
              </b-media-body>
            </b-media>
            <div class="my-1 py-25">
              <b-link
                v-for="tag in notice.tags"
                :key="tag"
              >
                <b-badge
                  pill
                  class="mr-75"
                  variant="light-primary"
                >
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <!-- v-html="notice.description" -->
            <div
              class="blog-content mb-3"
            >
              <rich-text-edit
                :text-one="$t('generic.description')"
                :text-two="'description'"
                :agreements="notice.description"
                :updating-data="false"
                :apply-edition-x="'edit'"
                :read-only-text="true"
              />
            </div>
            <div
              v-if="notice.head_content_type_key == 'FILE'"
            >
              <div v-if="validatorImageAllowed(notice.head_content.mime_type)">
                <b-img
                  fluid-grow
                  alt="Fluid-grow image"
                  :src="notice.head_content.url_absolute"
                />
              </div>
              <div v-else>
                <b-container fluid>
                  <b-row align-h="center">
                    <b-col>
                      <b-embed
                        type="iframe"
                        aspect="embed-responsive embed-responsive-16by9 embed-responsive-item"
                        class="text-center"
                        :src="notice.head_content.url_absolute"
                        allowfullscreen
                      />
                      <!-- </div> -->
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
            <div
              v-if="notice.head_content_type_key == 'URL'"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe
                        :src="notice.head_content_url"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowfullscreen
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <!-- <div
              class="blog-content mt-4"
              v-html="notice.content"
            /> -->
            <div class="blog-content mt-4">
              <rich-text-edit
                :text-one="$t('generic.description')"
                :text-two="'content'"
                :agreements="notice.content"
                :updating-data="false"
                :apply-edition-x="'edit'"
                :read-only-text="true"
              />
            </div>
            <hr class="my-2">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" />

              <!-- dropdown -->
              <div v-if="notice.share_post">
                <dropdown-share
                  :obj-new="notice"
                />
              </div>
              <!--/ dropdown -->
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    >
      <!-- Input search -->
      <!-- <search-sidebar-new
        :new-list="newListSidebar"
        @filter-query="handleFilters"
      /> -->
      <!--/ input search -->

      <!-- recent posts -->
      <posts-sidebar-new
        :recommended-notices="recommendedNotices"
      />
    <!--/ recent posts -->
    </div>
  </content-with-sidebar>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { mapGetters, mapActions } from 'vuex'
import { validatorImageAllowed } from '@core/utils/validations/validators'
import checkPermission from '@/auth/permissions'
import RichTextEdit from '@/views/news/components/RichTextEdit.vue'
import PostsSidebarNew from './PostsSidebarNew.vue'
import DropdownShare from '../components/DropdownShare.vue'

export default {
  components: {
    ContentWithSidebar,
    // SearchSidebarNew,
    PostsSidebarNew,
    DropdownShare,
    RichTextEdit,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      commentCheckmark: '',
      blogSidebar: {},
      recommendedNotices: [],
    }
  },
  computed: {
    ...mapGetters({
      notice: 'noticeStore/notice',
      newList: 'noticeStore/notices',
      path: 'path',
    }),
  },
  watch: {
    '$route.path': function () {
      this.getNoticeById()
    },
  },
  created() {
    if (this.checkPermission(['VIEW_NOTICES'])) {
      if (this.$route.params.id == null) {
        this.$router.push({ name: 'news' })
      }
      if (!this.checkPermission(['ALLOW_ALL', 'CREATE_NOTICES'])) {
        this.noticeActive = 1
      }
      this.getNoticeById()
      this.fetchDataRecommended()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    validatorImageAllowed,
    ...mapActions({
      fetchDataNoticeById: 'noticeStore/fetchDataById',
      fetchDataRecommendedNotices: 'noticeStore/fetchDataRecommended',
    }),
    async fetchDataRecommended() {
      await this.fetchDataRecommendedNotices({ paramsObj: { limitRandom: 3, active: this.noticeActive } }).then(response => {
        this.recommendedNotices = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async getNoticeById() {
      await this.fetchDataNoticeById(this.$route.params.id).then(response => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
